export const PATH = {
  shop: '/shop',
  shopNewArrivals: '/shop/new-arrivals',
  gift: '/gift',
  recycle: '/recycle',
  news: '/news',
  aboutUs: '/about-us',
  home: '/home',
  wishlist: '/wishlist',
  portfolio: '/portfolio',
  tos: '/tos',
  privacy: '/privacy',

  myAccount: '/my-account',
  myAccountOrders: '/my-account/orders',
  myAccountOrderDetails: '/my-account/orders/[orderId]',
  myAccountPortfolio: '/my-account/portfolio',

  collections: '/collections',

  categories: '/categories',

  cartCheckout: '/cart/checkout',
  cartCheckoutSuccess: '/cart/checkout/success',

  search: '/search',
  landing: '/',

  faq: '/faq',
};

export const SHOP_PATH_SLUG = {
  newArrivals: 'new-arrivals',
  allProducts: 'all-products',
  recommended: 'recommended',
  trending: 'trending',
};
