'use client';

import { Button, Flex } from '@chakra-ui/react';
import Link from 'next/link';
import PageTitle from '@/components/UI/Page/PageTitle';
import { PATH } from '@/constants/paths';

const RootNotFound = () => {
  return (
    <Flex
      alignItems="center"
      justifyItems="center"
      flexDir="column"
      h="100%"
      p="2rem"
      gap="2rem"
    >
      <PageTitle>Page Not Found</PageTitle>
      <Link href={PATH.home}>
        <Button variant="secondaryMd">Back to Home</Button>
      </Link>
    </Flex>
  );
};

export default RootNotFound;
