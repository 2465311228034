'use client';

import { FC } from 'react';
import Title, { TitleProps } from '@/components/UI/Title';

interface PageTitleProps extends TitleProps {}
const PageTitle: FC<PageTitleProps> = ({ children, ...textProps }) => {
  return (
    <Title
      fontSize={{ base: '1.875rem', '2xl': '3rem' }}
      lineHeight={{ base: '2.5rem', '2xl': '3.75rem' }}
      {...textProps}
    >
      {children}
    </Title>
  );
};

export default PageTitle;
